import { createStore } from 'vuex'
import {CryptoPay} from 'cryptopay-commerce/src/store/CryptoPay'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    CryptoPay
  }
})
